:root {
  --primary-color: #5B247A;/* #2A4E8C; Extracted from logo */
  --secondary-color:#1BCEDF; /* #F9A825; */
  --dark-bg: #1A1A1A;
  --light-bg: #FFFFFF;
}

[data-theme='dark'] {
  --bg-color: var(--dark-bg);
  --text-color: #FFFFFF;
}

[data-theme='light'] {
  --bg-color: var(--light-bg);
  --text-color: #333333;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: 'Radis Sans', sans-serif !important; /* Based on banner font */
}

.technoration-bg {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  color: white;
}

.feature-card {
  background: var(--bg-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.scroll-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.scroll-top.visible {
  opacity: 1;
}

.global-br{
  border-radius: 0.375rem;
}