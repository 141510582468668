/* PageLoader.css */
.page-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent; /* Transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it's on top of everything */
    transition: opacity 0.5s ease-out; /* Smooth fade-out transition */
  }
  
  .page-loader.fade-out {
    opacity: 0;
  }
  
  .loader-spinner {
    border: 8px solid rgba(255, 255, 255, 0.3); /* Light border color */
    border-top: 8px solid transparent; /* Transparent border for gradient effect */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
    background: conic-gradient(
      from 0deg,
      var(--primary-color),
      var(--secondary-color)
    ); /* Gradient for the spinner */
    -webkit-mask: radial-gradient(
      circle,
      transparent 50%,
      #000 51%
    ); /* Mask to create a ring effect */
    mask: radial-gradient(circle, transparent 50%, #000 51%);
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }