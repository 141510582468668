@font-face {
    font-family: 'Radis Sans';
    src: url('../../../public/fonts/Radis-Sans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Radis Sans';
    src: url('../../../public/fonts/Radis-Sans-Regular.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

/* src/assets/styles/theme.css */
body {
  font-family: 'Radis Sans', sans-serif !important;
}

/* Apply font to header and footer */
header, footer {
  font-family: 'Radis Sans', sans-serif !important;
}

/* Apply font to Bootstrap components */
.navbar, .nav-link, .dropdown-menu, .footer-link {
  font-family: 'Radis Sans', sans-serif !important;
}